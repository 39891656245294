.fileContainer {
  box-shadow: none !important;
  position: initial !important;
  border-radius: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
}
.uploaded_img {
  width: 10rem;
  height: 10rem;
}
.table.table-vertical-center th, .table.table-vertical-center td {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

tr {cursor: pointer;}

.upload_file_btn {
  width: 8rem;
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
  &::before {
    content: 'Upload file';
    display: inline-block;
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
    border: 1px solid #999;
    border-radius: 3px;
    padding: 7px 10px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    text-shadow: 1px 1px #fff;
    font-weight: 600;
    font-size: 10pt;
  }
  &:hover::before {
    border-color: black;
  }
  &:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
  }
}


